import { apiClient, type ExtractFromAPI, v1 } from "@netgame/openapi";
import { toast, type ToastTheme, type ToastPosition } from "vue3-toastify";

// @ts-ignore
import AAnimationSlide from "../../ui/atoms/animations/AAnimationSlide.vue";

import useAppInitData from "./useAppInitData";

type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
type Game = NonNullable<GamesPayload["games"]>[number];

const useAddGameToFavorite = <T extends (key: string) => string>({
	t,
	toastTheme = toast.THEME.LIGHT,
	toastPosition = toast.POSITION.BOTTOM_RIGHT
}: {
	t: T;
	toastTheme?: ToastTheme;
	toastPosition?: ToastPosition;
}) => {
	const { isDesktop } = useDevice();
	const { data: appInit } = useAppInitData();

	const activeIds = ref<{
		remove: { id: number | string | null; count: number; active: boolean };
		add: { id: number | string | null; count: number; active: boolean };
	}>({ remove: { id: null, count: 0, active: false }, add: { id: null, count: 0, active: false } });

	const showToast = (text: string, icon: string, type: keyof typeof activeIds.value) => {
		if (activeIds.value[type].id) {
			activeIds.value[type].count++;
			toast.update(activeIds.value[type].id as string, {
				toastId: type,
				autoClose: activeIds.value[type].count * 1500,
				containerId: "favorite-game-notify",
				render: () =>
					h("div", [
						h(
							AAnimationSlide,
							{
								active: activeIds.value[type].active,
								mode: "left-to-right",
								iterationCount: 3,
								distance: 5,
								duration: 0.2,
								class: "badge"
							},
							h("span", { class: "badge-text" }, activeIds.value[type].count)
						),
						text
					])
			});
		} else {
			activeIds.value[type].id = toast.success(text, {
				toastId: type,
				theme: toastTheme,
				position: isDesktop ? toastPosition : "top-center",
				transition: toast.TRANSITIONS.SLIDE,
				autoClose: 1500,
				containerId: "favorite-game-notify",
				icon: h("i", { class: `toast-icon ${icon}` }),
				onClose: () => {
					activeIds.value[type] = { id: null, count: 0, active: false };
				}
			});
		}
	};

	const handleToggleToFavoriteClick = async (game: Game) => {
		if (!game || !game.id || !appInit.value) {
			return;
		}

		const favoritesSet = new Set(appInit.value?.favoriteGames);
		if (game.isFavorite) {
			favoritesSet.delete(game.id);
			showToast(t("Game removed from your favorites"), "icon-favorite remove", "remove");
		} else {
			favoritesSet.add(game.id);
			showToast(t("Game added to your favorites"), "icon-favorite add", "add");
		}

		appInit.value.favoriteGames = [...favoritesSet];
		game.isFavorite = !game.isFavorite;

		await apiClient({
			path: "/rest/favorite-game/{id}/",
			method: "get",
			parameters: { path: { id: game.id } }
		});
	};

	watch(
		() => activeIds.value.remove.count,
		() => {
			activeIds.value.remove.active = true;
			setTimeout(() => {
				activeIds.value.remove.active = false;
			}, 300);
		},
		{ deep: true }
	);

	watch(
		() => activeIds.value.add.count,
		() => {
			activeIds.value.add.active = true;
			setTimeout(() => {
				activeIds.value.add.active = false;
			}, 300);
		},
		{ deep: true }
	);

	return { handleToggleToFavoriteClick };
};
export default useAddGameToFavorite;
